.top-menu-container {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0rem 1rem;
    position: relative;
    width: 100%;
    gap: 1rem;
    justify-content: space-between;
    margin-top: .5rem;
}

/* Search page specific layout */
.top-menu-container.search-page {
    flex-direction: row; /* Default to row for larger screens */
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

.top-menu-container.search-page .menu-and-logo {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.menu-options {
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
    margin-right: 2rem;
}

.m-option {
    background-color: transparent;
    color: white;
    border: none;
    cursor: pointer;
    position: relative;
    padding: 5px 0;
    transition: transform 0.3s ease;
}

.m-option:hover {
    background-color: transparent;
    transform: scale(1.1);
}

.m-option.active::after {
    content: '';
    display: flex;
    justify-self: center;
    width: 100%;
    height: 2px;
    background-color: rgb(174, 129, 223);
}

.login {
    background-color: transparent;
    border: 1px solid;
    border-radius: 2px;
    border-color: rgb(174, 129, 223);
    color: rgb(174, 129, 223);
    padding: 7.5px 10px;
    cursor: pointer;
    margin: 10px 0px;
}

.login:hover {
    background-color:rgb(174, 129, 223);
    color: white;
    transition: .3s ease;
}

.signup {
    background-color: rgb(174, 129, 223);
    border-radius: 2px;
    color: white;
    padding: 7.5px 10px;
    cursor: pointer;
    border: none;
    margin: 10px 0px;
}

.signup:hover {
    background-color: rgb(113, 58, 172);
}

.sign-up-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    flex: 1;
    justify-content: flex-end;
    min-width: 200px;
}

/* Hide signup section on search page */
.top-menu-container.search-page .sign-up-section {
    display: none;
}

.logo-section--compact {
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
    margin-right: auto;
}

/* Make logo smaller on search page */
.top-menu-container.search-page .logo-section--compact .cursor-logo {
    width: 24px;
    height: auto;
}

.logo {
    color: rgb(174, 129, 223);
    font-weight: 500;
    font-size: 1.1rem;
    font-family: 'Courier New', Courier, monospace;
}

.cursor-logo {
    width: 6%;
    height: auto;
}

.app-title {
    color: white;
    font-weight: 100;
    padding-bottom: 0.05rem;
}

/* Hamburger menu and mobile menu styles */
.hamburger-menu-wrapper {
    position: relative;
}

.hamburger-menu {
    display: none;
    color: white;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    padding: 0rem 0rem;
    margin: 0rem 0.5rem;
    margin-bottom: .2rem;
    z-index: 1001;
    position: relative;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
}

.mobile-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 200px;
    background-color: rgba(12, 12, 12, 0.8);
    z-index: 1000;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    opacity: 0;
    visibility: hidden;
}

.mobile-menu-content {
    padding: 4rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.hamburger-menu-wrapper:hover .mobile-menu {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
}

.hamburger-menu-wrapper:hover .hamburger-menu {
    opacity: 0;
    pointer-events: none;
}

.menu-and-logo {
    display: flex;
    flex: 1;
    min-width: 200px;
    align-items: center;
}

/* Search page specific styles */
.top-menu-container.search-page .hamburger-menu {
    display: block;
}

.top-menu-container.search-page .menu-options {
    display: none;
}

/* Search form styles for search page */
.top-menu-container.search-page .search-form {
    width: 100%;
    max-width: 800px;
    position: relative;
    left: auto;
    transform: none;
    margin: 0 auto;
}

.top-menu-container .search-form {
    height: 2.5rem;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    background-color: #2d2d2d;
}

.search-bar-container {
    flex: 1;
}

.search-input {
    width: 100%;
    height: 100%;
    padding: 0 1rem;
    border-radius: 1rem;
    background: transparent;
    border: none;
    color: white;
    outline: none;
}

.search-input::placeholder {
    color: #9ca3af;
}

.top-menu-container .search-form--menu .search-button {
    height: 1.75rem;
    width: 3rem;
    border-radius: .75rem;
    margin-right: 0.5rem;
    background-color: rgb(174, 129, 223);
    border: none;
    color: white;
    cursor: pointer;
}

.top-menu-container.search-page .mobile-menu .m-option {
    width: 80%;
    padding: 0.5rem;
    color: white;
    transition: background-color 0.2s ease;
}

/* User Profile Styles */
.user-profile {
    position: relative;
}

.user-avatar-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 20px;
    transition: background-color 0.2s;
}

.user-avatar-container:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.user-avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 8px;
}

.user-initial-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #3b82f6;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin-right: 8px;
}

.user-name {
    font-weight: 200;
    margin-right: 8px;
    color: white;
}

.subscription-badge {
    font-size: 10px;
    font-weight: bold;
    padding: 2px 6px;
    border-radius: 20px;
    text-transform: uppercase;
}

.subscription-badge--basic {
    background-color: #e5e7eb;
    color: #4b5563;
}

.subscription-badge--creator {
    background-color: #60a5fa;
    color: #1e3a8a;
}

.subscription-badge--pro {
    background-color: #f59e0b;
    color: #7c2d12;
}

.user-dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: transparent;
    color: white;
    border-radius: 4px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    min-width: 200px;
    z-index: 100;
    overflow: hidden;
    margin-top: 5px;
}

.dropdown-item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 12px 16px;
    margin: 0;
    border: none;
    background: none;
    text-align: left;
    cursor: pointer;
    transition: background-color 0.2s;
}

.dropdown-item:hover {
    background-color: rgb(174, 129, 223);
}

.logout-item {
    color: white;
}

.logout-item svg {
    margin-right: 8px;
    color: white;
}

/* Responsive design */
@media screen and (max-width: 768px) {
    .top-menu-container {
        padding: 0.5rem;
        gap: 0.5rem;
    }
    
    

    .sign-up-section {
        gap: 0.5rem;
    }

    .login, .signup {
        padding: 5px 8px;
        font-size: 0.9rem;
    }

    .mobile-menu {
        width: 250px;
        left: -250px;
    }

    .hamburger-menu-wrapper:hover .mobile-menu {
        transform: translateX(250px);
    }
}

.top-menu-container.search-page .menu-and-logo {
    display: flex;
    align-items: center;
    flex-grow: 0; /* Don't grow to take available space */
    min-width: auto; /* Allow it to take only the space it needs */
}

/* Search form styles for search page */
.top-menu-container.search-page .search-form {
    width: 100%;
    max-width: 400px; /* Limit the width for larger screens */
    margin: 0;
    flex-grow: 1; /* Allow it to grow but not beyond max-width */
}

/* Show sign-up section on larger screens for search page */
.top-menu-container.search-page .sign-up-section {
    display: flex; /* Show by default on larger screens */
}

@media screen and (max-width: 580px) {
    .mobile-menu {
        width: 200px;
        left: -200px;
    }

    .top-menu-container.search-page {
        flex-direction: column; /* Column layout only on mobile */
        gap: 0.75rem;
    }

    .menu-options {
        gap: 0;
        margin-right: 1rem;
    }

    .cursor-logo { 
        width: 40%;
    }

    .sign-up-section {
        justify-content: center;
    }
    
    .top-menu-container.search-page .logo-section--compact .cursor-logo {
        width: 30px;
    }

    .hamburger-menu-wrapper:hover .mobile-menu {
        transform: translateX(200px);
    }

    .top-menu-container.search-page .sign-up-section {
        display: none; /* Hide only on mobile */
    }
}

