/* Reset default styles */
*, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  background-color: rgb(24, 24, 27);
}

body {
  position: relative;
}

/* App container */
.App {
  background-color: rgb(54, 54, 145);
  min-height: 100vh;
  color: rgb(243, 244, 246);
  width: 100%;
  overflow-x: hidden;
}

/* Hero section - takes up the full viewport height */
.hero-section {
  min-height: 80vh; /* Full viewport height (minimum) */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.nav-divider {
  width: 100%;
  height: 1px;
  background-color: rgba(156, 163, 175, 0.5);
  border: none;
  margin: 0;
  padding: 0;
}

/* About container with proper spacing */
.about-container {
  width: 100%;
  padding: 2rem 1rem;
  margin-top: 2rem;
}

/* Form elements styling */
input, 
select {
  background-color: rgb(39, 39, 42);
  border: 1px solid rgb(63, 63, 70);
  color: rgb(243, 244, 246);
  padding: 10px 16px;
  margin: 10px;
  border-radius: 8px;
  font-size: 0.875rem;
  outline: none;
  transition: border-color 0.2s ease;
}

input:focus,
select:focus {
  border-color: rgb(59, 130, 246);
}

input::placeholder {
  color: rgb(156, 163, 175);
}

button {
  background-color: rgb(59, 130, 246);
  color: white;
  margin: 10px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s ease;
}

button:hover {
  background-color: rgb(37, 99, 235);
}

button:disabled {
  background-color: rgb(75, 85, 99);
  cursor: not-allowed;
}

/* Headings */
h1 {
  font-size: 1.5rem;
  font-weight: 600;
  color: rgb(243, 244, 246);
}

h2 {
  font-size: 1.25rem;
  font-weight: 600;
  padding: 1rem 0;
  color: rgb(243, 244, 246);
}

/* Video list container */
#videoList {
  display: grid;
  gap: 1rem;
  padding: 1rem;
  max-width: 1600px;
  margin: 0 auto;
}

/* Responsive grid */
@media (min-width: 768px) {
  #videoList {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  #videoList {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1280px) {
  #videoList {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* Form container */
form {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
  background-color: rgb(39, 39, 42);
  border-radius: 8px;
}

.mb-8 {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Messages */
p {
  color: rgb(156, 163, 175);
  padding: 1rem 0;
}

/* Selection color */
::selection {
  background-color: rgb(59, 130, 246);
  color: white;
}

/* Scrollbar styling */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: rgb(24, 24, 27);
}

::-webkit-scrollbar-thumb {
  background: rgb(63, 63, 70);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(82, 82, 91);
}

/* Fix for any potential horizontal overflow */
#root {
  overflow-x: hidden;
  width: 100%;
}

.full-screen { 
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}