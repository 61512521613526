/* plan-selection.css */
.plan-selection-container {
  width: 100%;
  padding: 1rem 0;
}

.plan-options-horizontal {
  display: flex;
  gap: 1rem;
  width: 100%;
  justify-content: center;
}

.plan-card {
  flex: 1;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 1.25rem;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  background-color: rgb(45, 45, 48);
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.plan-card:hover {
  border-color: rgba(255, 255, 255, 0.2);
}

.plan-card.selected {
  border-color: rgb(174, 129, 223);
  background-color: rgba(59, 130, 246, 0.05);
}

.plan-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
}

.plan-name {
  font-weight: 600;
  font-size: 1.1rem;
  margin: 0;
  color: rgb(243, 244, 246);
}

.plan-tag {
  background-color: rgb(59, 130, 246);
  color: white;
  font-size: 0.7rem;
  font-weight: 500;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
}

.plan-price {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 1rem;
}

.price {
  font-size: 1.5rem;
  font-weight: 600;
  color: rgb(243, 244, 246);
}

.duration {
  font-size: 0.875rem;
  color: rgb(156, 163, 175);
  margin-top: 0.25rem;
}

.plan-features {
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;
}

.plan-features li {
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  color: rgb(209, 213, 219);
}

.plan-features li::before {
  content: "✓";
  position: absolute;
  left: 0;
  color: rgb(174, 129, 223);
}

.plan-selection-indicator {
  position: absolute;
  top: 1rem;
  right: 1rem;
  height: 20px;
  width: 20px;
}

.selected-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: rgb(59, 130, 246);
  color: white;
  font-size: 12px;
  font-weight: bold;
}

/* Responsive styles */
@media (max-width: 768px) {
  .plan-options-horizontal {
    flex-direction: column;
  }
  
  .plan-card {
    margin-bottom: 0.5rem;
  }
}