.pricing-container {
    text-align: center;
    padding: 2rem;
    background: transparent;
    color: white;
}

.pricing-title {
    font-size: 2rem;
    margin-bottom: 2rem;
    color: #f0f0f0;
}

.pricing-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
}

.pricing-card {
    background: #2a2a2a;
    border: 1px solid #444;
    border-radius: 16px;
    padding: 1.5rem;
    max-width: 300px;
    text-align: left;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.pricing-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.4);
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.plan-name {
    font-size: 1.25rem;
    font-weight: bold;
    color: #e0e0e0;
}

.plan-tag {
    font-size: 0.875rem;
    background: rgb(174, 129, 223);
    color: white;
    padding: 0.25rem 0.5rem;
    border-radius: 8px;
    font-weight: bold;
}

.price-section {
    font-size: 2rem;
    font-weight: bold;
    margin: 1rem 0;
}

.price {
    color: #fff;
}

.duration {
    font-size: 1rem;
    color: #aaa;
}

.features-list {
    list-style: none;
    padding: 0;
    margin: 1rem 0;
    color: #c0c0c0;
}

.features-list li {
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
}

.features-list li:before {
    content: "✔";
    margin-right: 0.5rem;
    color: rgb(174, 129, 223);
}

.get-started-btn {
    background: #333;
    color: #fff;
    padding: 0.75rem 1rem;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s ease;
}

.get-started-btn:hover {
    background: rgb(174, 129, 223);
    color: white;
}

.highlight {
    border: 2px solid rgb(174, 129, 223);
}
