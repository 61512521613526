.submit-button {
  transition: background-color 0.2s ease-in-out;
}
.submit-button:hover:not([disabled]) {
  background-color: #2563eb; /* Darker blue for hover */
}
.submit-button:disabled {
  background-color: #94a3b8; /* Disabled gray */
  cursor: not-allowed;
}

.login-link {
  color: rgb(174, 129, 223);
}

.title-optimizer-input-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: #2d2d2d;
  border-radius: 0.5rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
}

.title-optimizer-input-container:focus-within {
  outline: none;
  box-shadow: 0 0 0 2px rgb(174, 129, 223);
}

/* Input Field */
.title-optimizer-input {
  flex: 1;
  background: transparent;
  color: #ffffff;
  border: none;
  outline: none;
  font-size: 0.875rem;
  padding: 0.5rem;
  width: 100%;
}

.title-optimizer-input::placeholder {
  color: #9ca3af;
}

/* Prevent input field turning white on autofill */
.title-optimizer-input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #2d2d2d inset;
  -webkit-text-fill-color: white;
}

/* Button */
.title-optimizer-button {
  background-color: transparent;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  cursor: pointer;
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
  min-width: 40px;
}

.title-optimizer-button:hover:not(:disabled) {
  background-color: rgb(174, 129, 223);
  border-radius: 0.375rem;
  transform: scale(1.05);
}

.title-optimizer-button:disabled {
  background-color: #4b5563;
  cursor: not-allowed;
}

/* Add these styles to your title_optimizer.css */
.title-optimizer-results {
  margin-top: 2rem;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.title-optimizer-container {
  padding: 1rem;
  background-color: rgb(65, 65, 71);
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  min-height: 400px;
  margin-bottom: 3rem;
  width: 100%;
  box-sizing: border-box;
}

@media (min-width: 768px) {
  .title-optimizer-container {
    padding: 1.5rem;
    min-height: 500px;
  }
}

.mt-8 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1rem 0;
  width: 100%;
}

@media (min-width: 768px) {
  .mt-8 {
    flex-direction: row;
  }
}

.metrics-container {
  width: 100%;
  max-width: 100%;
  margin: 20px auto 0;
  opacity: 1;
  transition: opacity 0.3s ease;
}

@media (min-width: 768px) {
  .metrics-container {
    max-width: 60%;
    margin: 0;
  }
}

/* Ensure progress bars are visible even at 0% */
.progress-bar-container {
  width: 100%;
  background-color: #f3f4f6;
  height: 8px;
  border-radius: 4px;
  margin: 8px 0;
  overflow: hidden;
}

/* SVG Arrow Icon */
.up-arrow-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.loading-container {
  display: flex;
  align-items: center;
}

.loading-icon {
  width: 1rem;
  height: 1rem;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Suggested Keywords */
.keywords-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.5rem;
  margin-bottom: .5rem;
  gap: 0.5rem;
}

.keyword-suggestion {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  background-color: transparent;
  color: white;
  border: 1px solid;
  border-color: rgb(174, 129, 223);
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-bottom: 0.25rem;
}

@media (min-width: 768px) {
  .keyword-suggestion {
    padding: 0.25rem 0.75rem;
  }
}

.keyword-suggestion:hover {
  background-color: rgb(174, 129, 223);
  transform: scale(1.05);
}

.keyword-suggestion.selected {
  background-color: rgb(174, 129, 223);
}

.keyword-suggestion .keyword-icon {
  opacity: 0;
  transition: opacity 0.2s ease;
}

.keyword-suggestion.selected .keyword-icon {
  opacity: 1;
}

.keyword-suggestion:hover .keyword-icon {
  opacity: 1;
}

.suggested-keywords h4 {
  color: white;
  font-size: 1rem;
  font-weight: 600;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  margin-left: .5rem;
}

@media (min-width: 768px) {
  .suggested-keywords h4 {
    font-size: 1.125rem;
    margin-top: 2rem;
    margin-left: .75rem;
  }
}

/* donut chart */
.donut-chart {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 1.5rem 0;
  padding: 0;
}

@media (min-width: 768px) {
  .donut-chart {
    margin: 0;
  }
}

.donut-chart-ring {
  width: 100px;
  height: 100px;
}

@media (min-width: 768px) {
  .donut-chart-ring {
    width: 120px;
    height: 120px;
  }
}

.donut-chart svg {
  width: 90%;
  height: auto;
}

.donut-chart-background,
.donut-chart-progress {
  fill: none;
  stroke-width: 16;
}

.donut-chart-background {
  stroke: #f3f4f6;
}

.donut-chart-progress {
  stroke-linecap: round;
  transition: stroke-dashoffset .2s ease-in-out, stroke 0.5s ease;
}

/* Progress Bar Styles */
.progress-bar-container {
  width: 100%;
  background-color: #f3f4f6;
  height: 8px;
  border-radius: 4px;
  margin: 8px 0;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  width: 2px;
  min-width: 2px;
  transition: width 1s ease-in-out, background-color 0.5s ease;
  border-radius: 4px;
}

.metric-item {
  margin: 12px 0;
}

@media (min-width: 768px) {
  .metric-item {
    margin: 16px 0;
  }
}

.metric-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  font-size: 13px;
  color: white;
}

@media (min-width: 768px) {
  .metric-header {
    font-size: 14px;
  }
}

/* Container Styles */
.title-optimizer-container {
  padding: 1rem;
  background-color: rgb(65, 65, 71);
  border-radius: 0.5rem;
}

@media (min-width: 768px) {
  .title-optimizer-container {
    padding: 1.5rem;
  }
}

/* Input Label */
.input-label {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: white;
  margin-bottom: 0.5rem;
}

/* Input Field */
.input-field {
  width: 100%;
  padding: 0.5rem 1rem;
  border: 1px solid #d2d6dc;
  border-radius: 0.375rem;
  outline: none;
}

.input-field:focus {
  ring-width: 2px;
  ring-color: #4299e1;
}

/* Loading Section */
.loading-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #718096;
}

/* Score Display */
.score-title {
  font-size: 1.125rem;
  font-weight: 600;
}

.score-value {
  font-size: 1.5rem;
  font-weight: 700;
}

.low-score {
  color: #f56565;
}

.medium-score {
  color: #ffe27b;
}

.high-score {
  color: #48bb78;
}

/* Tip Box */
.tip-box {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #fefcbf;
  border: 1px solid #faf089;
  border-radius: 0.375rem;
}

.tip-text {
  font-size: 0.875rem;
  color: #975a16;
}

/* Add to title_optimizer.css */
.error-message {
  display: flex;
  align-items: center;
  background-color: #FEE2E2;
  border: 1px solid #F87171;
  color: #B91C1C;
  padding: 8px;
  border-radius: 6px;
  margin-bottom: 16px;
  flex-wrap: wrap;
  font-size: 0.875rem;
}

@media (min-width: 768px) {
  .error-message {
    padding: 12px;
    font-size: 1rem;
  }
}

.error-icon {
  margin-right: 6px;
  width: 16px;
  height: 16px;
}

@media (min-width: 768px) {
  .error-icon {
    margin-right: 8px;
    width: 18px;
    height: 18px;
  }
}

.upgrade-link {
  margin-left: auto;
  background-color: #B91C1C;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 500;
  transition: background-color 0.2s;
}

@media (min-width: 768px) {
  .upgrade-link {
    padding: 5px 10px;
  }
}

.upgrade-link:hover {
  background-color: #991B1B;
}

.subscription-limit-alert {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  margin-bottom: 16px;
  background-color: #fffbeb;
  border-left: 4px solid #f59e0b;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
  .subscription-limit-alert {
    flex-direction: row;
    padding: 16px;
    margin-bottom: 20px;
  }
}

.limit-icon {
  color: #f59e0b;
  flex-shrink: 0;
  margin-right: 8px;
  margin-bottom: 8px;
}

@media (min-width: 768px) {
  .limit-icon {
    margin-right: 12px;
    margin-top: 3px;
    margin-bottom: 0;
  }
}

.limit-content {
  flex: 1;
}

.limit-title {
  font-weight: 600;
  margin: 0 0 4px 0;
  color: #92400e;
  font-size: 0.95rem;
}

@media (min-width: 768px) {
  .limit-title {
    font-size: 1rem;
  }
}

.limit-message {
  color: #78350f;
  margin: 0 0 12px 0;
  font-size: 0.85rem;
}

@media (min-width: 768px) {
  .limit-message {
    font-size: 0.9rem;
  }
}

.limit-actions {
  margin-top: 8px;
}

@media (min-width: 768px) {
  .limit-actions {
    margin-top: 10px;
  }
}

.upgrade-button {
  display: inline-block;
  padding: 6px 12px;
  background-color: #f59e0b;
  color: white;
  font-weight: 500;
  border-radius: 4px;
  text-decoration: none;
  font-size: 0.8rem;
  transition: background-color 0.2s;
}

@media (min-width: 768px) {
  .upgrade-button {
    padding: 8px 16px;
    font-size: 0.875rem;
  }
}

.upgrade-button:hover {
  background-color: #d97706;
}