.video-carousel {
  position: relative;
  width: 100%;
  padding: 15px 0;
}

@media (min-width: 768px) {
  .video-carousel {
    width: 100%;
    padding: 20px 0;
  }
}

.video-carousel-contents {
  display: flex;
  justify-content: center;
}

.video-carousel__title {
  font-size: 1.1rem;
  font-weight: 600;
  color: rgb(243, 244, 246);
  margin-bottom: 0.75rem;
  padding-left: 0.5rem;
}

@media (min-width: 768px) {
  .video-carousel__title {
    font-size: 1.25rem;
    margin-bottom: 1rem;
    padding-left: 1rem;
  }
}

.video-carousel__container {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.video-carousel__track {
  display: flex;
  gap: 0.75rem;
  transition: transform 0.5s ease;
}

@media (min-width: 768px) {
  .video-carousel__track {
    gap: 1rem;
  }
}

.video-carousel__item {
  flex: 0 0 80%;
  min-width: 0;
}

@media (min-width: 480px) {
  .video-carousel__item {
    flex: 0 0 calc(50% - 0.75rem);
  }
}

@media (min-width: 768px) {
  .video-carousel__item {
    flex: 0 0 calc(33.333% - 1rem);
  }
}

@media (min-width: 1024px) {
  .video-carousel__item {
    flex: 0 0 300px;
  }
}

.video-carousel__nav-button {
  position: absolute;
  top: 40%;
  transform: translateY(-80%);
  width: 28px;
  height: 28px;
  background-color: rgba(89, 81, 99, 0.5);
  color: #ffffff;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  transition: background-color 0.2s ease;
}

@media (min-width: 768px) {
  .video-carousel__nav-button {
    width: 30px;
    height: 30px;
  }
}

/* Make the SVG icons explicitly styled */
.video-carousel__nav-button svg {
  color: #ffffff;
  width: 20px !important;
  height: 20px !important;
  fill: currentColor;
  stroke: currentColor;
}

@media (min-width: 768px) {
  .video-carousel__nav-button svg {
    width: 24px !important;
    height: 24px !important;
  }
}

.video-carousel__nav-button:hover {
  background-color: rgb(174, 129, 223);
}

.video-carousel__nav-button:disabled {
  opacity: 0;
  cursor: default;
  pointer-events: none;
}

.video-carousel__nav-button--prev {
  left: 0.25rem;
}

.video-carousel__nav-button--next {
  right: 0.25rem;
}

@media (min-width: 768px) {
  .video-carousel__nav-button--prev {
    left: 0.5rem;
  }
  
  .video-carousel__nav-button--next {
    right: 0.5rem;
  }
}

/* Hide scrollbar for different browsers */
.video-carousel__track {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.video-carousel__track::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}