.select-plan-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 2rem;
    background-color: rgb(24, 24, 27);
    color: rgb(243, 244, 246);
}

.select-plan-box {
    width: 100%;
    max-width: 900px;
    background: rgb(39, 39, 42);
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    padding: 2rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.select-plan-header {
    text-align: center;
    margin-bottom: 2rem;
}

.select-plan-header h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
    color: rgb(243, 244, 246);
    text-align: left;
    padding-left: 1rem;

}

.select-plan-header p {
    color: rgb(156, 163, 175);
    font-size: 0.95rem;
}

.google-info {
    background-color: rgba(119, 164, 237, 0.05);
    border: 1px solid rgba(174, 129, 223, .3);
    border-radius: 6px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    padding-left: 1rem;
}

.google-info p {
    color: rgb(174, 129, 223);
    margin: 0;
    font-size: 0.875rem;
}

.error-message {
    color: rgb(239, 68, 68);
    margin: 1rem 0;
    text-align: center;
    padding: 0.75rem;
    background-color: rgba(239, 68, 68, 0.05);
    border-radius: 6px;
    font-size: 0.875rem;
    border: 1px solid rgba(239, 68, 68, 0.2);
}

.plan-submit-button {
    display: block;
    width: 100%;
    padding: 0.75rem;
    background-color: rgb(174, 129, 223);
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 0.95rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
    margin-top: 1.5rem;
    text-align: center;
}

.plan-submit-button:hover {
    background-color: rgb(113, 58, 172);
}

.plan-submit-button:active {
    background-color: rgb(113, 58, 172);
}

.plan-submit-button:disabled {
    background-color: rgb(75, 85, 99);
    cursor: not-allowed;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .select-plan-box {
        padding: 1.5rem;
    }
}

@media (max-width: 480px) {
    .select-plan-container {
        padding: 1rem;
    }
}