/* Dark Theme Payment Page Styles */
.payment-container {
  max-width: 760px;
  margin: 0 auto;
  padding: 2.5rem 1.5rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
  color: #e2e8f0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: transparent; /* Dark background */
}

.payment-card {
  background-color: rgb(39, 39, 42); /* Dark card background */
  border-radius: 12px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  position: relative;
  border: 1px solid #374151;
}

.payment-header {
  padding: 2rem 2rem 1rem;
  text-align: center;
  background-color: rgb(39, 39, 42); /* Darker header background */
  border-bottom: 1px solid #374151;
}

.payment-header h1 {
  margin: 0;
  font-size: 1.75rem;
  font-weight: 700;
  color: #f3f4f6;
}

.subtitle {
  margin-top: 0.5rem;
  color: #9ca3af;
  font-size: 1rem;
}

.payment-summary {
  padding: 2rem;
  background-color: rgb(39, 39, 42);
}

.payment-summary h2 {
  margin: 0 0 1.5rem;
  font-size: 1.25rem;
  font-weight: 600;
  color: #f3f4f6;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.summary-icon {
  width: 20px;
  height: 20px;
  color: rgb(174, 129, 223);
}

.plan-details {
  display: flex;
  justify-content: space-between;
  padding: 1.25rem;
  margin-bottom: 1.5rem;
  background-color: rgb(31, 36, 45); /* Slightly lighter background for contrast */
  border-radius: 8px;
  border: 1px solid #4b5563;
}

.plan-name {
  font-weight: 600;
  font-size: 1.1rem;
  color: #e2e8f0;
}

.plan-price {
  font-weight: 700;
  font-size: 1.1rem;
  color: rgb(174, 129, 223);
}

.plan-features {
  margin: 1.5rem 0;
  padding: 0 0 0 1.5rem;
}

.plan-features li {
  margin-bottom: 0.75rem;
  color: #d1d5db;
  position: relative;
}

.plan-features li::before {
  content: "✓";
  position: absolute;
  left: -1.5rem;
  color: rgb(174, 129, 223);
  font-weight: bold;
}

.billing-info {
  margin-top: 1.5rem;
  font-size: 0.95rem;
  color: #9ca3af;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.info-badge {
  background-color: rgba(174, 129, 223, 0.2);
  color: rgb(174, 129, 223);
  padding: 0.25rem 0.75rem;
  border-radius: 100px;
  font-size: 0.8rem;
  font-weight: 500;
}

.checkout-section {
  padding: 0 2rem 1.5rem;
}

.payment-submit-btn {
  width: 100%;
  padding: 1rem 1.5rem;
  background-color: rgb(174, 129, 223);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  box-shadow: 0 4px 6px rgba(174, 129, 223, 0.25);
}

.payment-submit-btn:hover {
  background-color: rgb(150, 110, 190);
  transform: translateY(-2px);
  box-shadow: 0 7px 14px rgba(174, 129, 223, 0.25);
}

.payment-submit-btn:active {
  transform: translateY(0);
  box-shadow: 0 4px 6px rgba(174, 129, 223, 0.25);
}

.payment-submit-btn:disabled {
  background-color: #b794f4;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
  opacity: 0.7;
}

.btn-icon, .back-icon {
  width: 18px;
  height: 18px;
}

.payment-back-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin: 1rem auto 2rem;
  padding: 0.75rem 1.5rem;
  background: transparent;
  border: 1px solid #4b5563;
  border-radius: 8px;
  color: #9ca3af;
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.payment-back-btn:hover {
  background-color: #2d3748;
  border-color: #6b7280;
  color: #e2e8f0;
}

.payment-back-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.payment-error {
  margin-top: 1rem;
  padding: 1rem;
  background-color: rgba(220, 38, 38, 0.2);
  color: #f87171;
  border-radius: 8px;
  font-size: 0.95rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid rgba(248, 113, 113, 0.4);
}

.payment-security {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  margin-top: 1.5rem;
  font-size: 0.9rem;
  color: #9ca3af;
}

.security-icon {
  width: 16px;
  height: 16px;
  color: #9ca3af;
}

/* Loading States */
.loading-spinner, .btn-spinner {
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 3px solid #fff;
  width: 18px;
  height: 18px;
  animation: spin 1s linear infinite;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  margin: 2rem auto 1rem;
  border: 3px solid rgba(174, 129, 223, 0.2);
  border-top: 3px solid rgb(174, 129, 223);
}

.loading-text {
  text-align: center;
  color: #9ca3af;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Payment success styles */
.payment-success-message {
  text-align: center;
  padding: 2.5rem 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  position: relative;
}

.payment-title h1 {
  text-align: center;
}

.success-icon {
  font-size: 4rem;
  color: #10b981;
  margin-bottom: 1.5rem;
}

.subscription-details {
  margin-top: 2rem;
  padding: 1.5rem;
  background-color: #1f2937;
  border-radius: 8px;
  border: 1px solid #065f46;
  text-align: left;
}

.subscription-details h3 {
  margin-top: 0;
  font-size: 1.1rem;
  color: #34d399;
}

.subscription-details p {
  margin: 0.5rem 0;
  color: #d1d5db;
}

.subscription-status {
  margin: 1.5rem 0;
  padding: 1.25rem;
  border-radius: 8px;
  text-align: center;
}

.subscription-created {
  background-color: rgba(59, 130, 246, 0.2);
  border: 1px solid #3b82f6;
  color: #93c5fd;
}

.subscription-processing {
  background-color: rgba(245, 158, 11, 0.2);
  border: 1px solid #f59e0b;
  color: #fbbf24;
}

.subscription-active {
  background-color: rgba(5, 150, 105, 0.2);
  border: 1px solid #059669;
  color: #34d399;
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .payment-container {
    padding: 1.5rem 1rem;
  }
  
  .payment-header,
  .payment-summary,
  .checkout-section {
    padding: 1.5rem;
  }
  
  .payment-header h1 {
    font-size: 1.5rem;
  }
  
  .plan-details {
    padding: 1rem;
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
  }
  
  .plan-price {
    font-size: 1.25rem;
  }
}