.search-demo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.search-form--demo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: #2d2d2d;
  box-shadow: 0px 5px 5px rgb(17, 17, 17);
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin-bottom: 2rem;
  width: 40%;
  min-width: 300px;
}

.search-bar-container {
  flex: 1;
  position: relative;
  min-width: 0;
  padding: 0;
}

.search-input--demo {
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
  color: #ffffff;
  font-size: 1rem;
  padding: 0.5rem;
  caret-color: transparent;
  font-family: system-ui, -apple-system, sans-serif;
  letter-spacing: normal;
}

.search-input--demo::placeholder {
  color: #9ca3af;
  font-family: system-ui, -apple-system, sans-serif;
  letter-spacing: normal;
}

/* Typing cursor animation */
.search-input--demo::after {
  content: '';
  border-right: 2px solid #ffffff;
  animation: blink 1s infinite;
  margin-left: 2px;
}

.search-bar-container {
  position: relative;
  flex: 1;
  min-width: 0;
}

/* Animated cursor */
.typing-cursor {
  display: inline-block;
  width: 2px;
  height: 1.2em;
  background-color: #ffffff;
  animation: blink 1s infinite;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: left 0.1s ease-out;
}

/* Add this new class for when text is present */
.typing-cursor--with-text {
    /* This will be controlled by JavaScript */
    left: calc(.5rem + var(--text-width, 0px));
}


@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.search-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: rgb(174, 129, 223);
  color: white;
  border: none;
  padding: 0.5rem;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.search-button:hover:not(:disabled) {
  background-color: rgb(135, 40, 236);
  transform: scale(1.05);
}

.search-button:disabled {
  background-color: #4b5563;
  cursor: not-allowed;
}

.search-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.spinner {
  width: 1rem;
  height: 1rem;
  border: 2px solid transparent;
  border-top: 2px solid white;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Updated video-card styling to have thumbnail flush with left edge */
.search-demo .video-card {
  display: flex;
  align-items: stretch;
  width: 350px;
  height: 85px;
  background-color: rgba(67, 67, 67, 0.7);
  box-shadow: 0px 5px 5px rgb(17, 17, 17);
  border-radius: 0.5rem;
  overflow: hidden;
}

.search-demo .video-card:hover{
  background-color: #222222;
  transform: translateY(-4px);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.search-demo .video-card__thumbnail-container {
  width: 120px;
  flex-shrink: 0;
  position: relative;
}

.search-demo .video-card__thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0;
  display: block;
}

.search-demo .video-card__content {
  flex: 1;
  max-width: 230px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.search-demo .video-card__duration {
  position: absolute;
  bottom: .4rem;
  right: .3rem;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 0.7rem;
  padding: 0.1rem 0.3rem;
  border-radius: 0.2rem;
}

.search-demo .video-card__title {
  font-size: .85rem;
  font-weight: 300;
  color: rgb(243, 244, 246);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 0 0 0.25rem 0;
}

.search-demo .video-card__channel {
  font-size: 0.75rem;
  color: rgb(156, 163, 175);
  font-weight: 400;
  margin-bottom: 0.25rem;
  padding: 0;
}

.search-demo .video-card__stats {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 0.75rem;
  color: rgb(156, 163, 175);
}

.search-demo .video-card__stat {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.search-demo .video-card__icon {
  display: inline;
  opacity: 0.75;
}

/* Updated video-results styling for better responsiveness */
.video-results {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  opacity: 0;
  transition: opacity var(--fade-duration) ease-in-out;
  width: 100%;
  max-width: 1200px;
}

.video-results--visible {
  opacity: 1;
}

.video-results:not(.video-results--results-visible) {
  opacity: 0;
}

.video-results.video-results--results-visible {
  opacity: 1;
}

/* Different colors for different terms */
.search-input--demo[data-term-index="0"] { color: #FF6B6B; }
.search-input--demo[data-term-index="1"] { color: #4ECDC4; }
.search-input--demo[data-term-index="2"] { color: #FFD93D; }
.search-input--demo[data-term-index="3"] { color: #95E1D3; }
.search-input--demo[data-term-index="4"] { color: #FF8B94; }

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes blink {
  from, to { opacity: 1; }
  50% { opacity: 0; }
}

/* Enhanced responsive design */
@media (max-width: 1200px) {
  .video-results {
    max-width: 800px;
    justify-content: center;
  }
  
  .search-demo .video-card {
    width: 100%;
    max-width: 350px;
  }
}

@media (max-width: 768px) {
  .video-results {
    flex-direction: column;
    align-items: center;
    max-width: 400px;
  }
  
  .search-demo .video-card {
    width: 100%;
    max-width: 95%;
  }
  
  .search-form--demo {
    width: 80%;
  }
}

@media (max-width: 480px) {
  .search-form--demo {
    width: 95%;
    min-width: auto;
  }
  
  .search-demo .video-card {
    height: auto;
    min-height: 85px;
  }
  
  .search-demo .video-card__thumbnail-container {
    width: 100px;
  }
  
  .search-demo .video-card__content {
    max-width: none;
    padding: 0.4rem 0.6rem;
  }
}

:root {
  --fade-duration: 1000ms;
}