/* search_results.css */
.search-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}

/* Adjust padding for larger screens */
@media (min-width: 768px) {
  .search-results {
    padding: 2.5rem 5rem;
  }
}

.compact-search-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  padding: 0;
  margin: 0;
}

@media (min-width: 768px) {
  .compact-search-container {
    max-width: 600px;
  }
}

.search-form--compact {
  display: flex;
  transform: none;
  width: 100%;
  height: 40px;
  background-color: #2d2d2d;
  border-radius: 20px;
  padding: 0 0.5rem;
}

.search-form--compact .search-input {
  height: 100%;
  font-size: 0.875rem;
  padding: 0 1rem;
}

.search-form--compact .search-button {
  padding: 0.25rem;
  height: 30px;
  width: 45px;
  margin: 5px;
  border-radius: 1rem;
}

.search-form--compact .search-icon {
  width: 1rem;
  height: 1rem;
}

.video-carousel-section {
  margin-bottom: 1.5rem;
  width: 100%;
}

.title-optimizer-section {
  width: 100%;
  position: relative;
  z-index: 1;
}

.optimizer-label {
  display: flex;
  width: 100%;
  align-items: flex-start;
  margin-top: .5rem;
  margin-bottom: .5rem;
}

/* Responsive font sizes */
.optimizer-label h1 {
  font-size: 1.5rem;
}

@media (min-width: 768px) {
  .optimizer-label h1 {
    font-size: 2rem;
  }
}

.results-descriptor {
  display: flex;
  width: 100%;
  align-items: flex-start;
}

/* Responsive font sizes for search results title */
.results-descriptor h2 {
  font-size: 1.2rem;
  word-break: break-word;
}

@media (min-width: 768px) {
  .results-descriptor h2 {
    font-size: 1.5rem;
  }
}

/* Carousel specific styles */
.search-results .video-carousel {
  width: 100%;
  padding: 0;
}

.search-results .video-carousel__container {
  position: relative;
  overflow: hidden;
}

.search-results .video-carousel__track {
  display: flex;
  transition: transform 0.3s ease;
  gap: 10px;
  min-height: auto;
}

@media (min-width: 768px) {
  .search-results .video-carousel__track {
    gap: 16px;
  }
}

/* Navigation buttons */
.search-results .video-carousel__nav-button {
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  border: 2px solid;
  border-color: rgba(174, 129, 223);
  color: white;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
}

@media (min-width: 768px) {
  .search-results .video-carousel__nav-button {
    width: 40px;
    height: 40px;
  }
}

.search-results .video-carousel__nav-button--prev {
  left: 0;
}

.search-results .video-carousel__nav-button--next {
  right: 0;
}

.search-results__title {
  margin-right: auto;
}

.search-results__empty {
  text-align: center;
  padding: 2rem;
  color: white;
  width: 100%;
}

