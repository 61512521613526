.nav-container {
    top: 0;
    z-index: 50;
    background-color: rgb(24, 24, 27);
    width: 100%;
}

.nav-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4rem 1rem;
    border: none;
    gap: 10%;
}

.nav-content--compact {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 75px;
    min-height: 75px;
    width: 100%;
    padding: 0;
    margin: 0 auto;
}

.back-button {
    background: none;
    border: none;
    color: rgb(174, 129, 223);
    cursor: pointer;
    padding: 0.25rem; /* Reduced padding */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.375rem;
    transition: all 0.2s ease;
}

.back-button:hover {
    background-color: rgba(174, 129, 223, 0.1);
}

.back-icon {
    width: 1.5rem;
    height: 1.5rem;
}

.tagline-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tagline {
  font-size: 5rem;
  font-weight: 700;
  padding-bottom: .25rem;
  color: white;
  text-align: center;
}

.tagline .highlight {
  background: linear-gradient(90deg, #ad6eff, #aac0f8, #ebe3ff, #9353d7, #ad6eff);
  background-size: 200% 100%;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  display: inline-block;
  padding: 0 5px;
  border: none;
  animation: shimmer 3s linear infinite;
}

@keyframes shimmer {
  0% {
    background-position: 200% 50%;
  }
  100% {
    
    background-position: 0% 50%;
  }
}

.motto {
  color: #dcdcdc;
  font-weight: 100;
  font-size: 1.5rem;
  padding: 0;
}

.search-form {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background-color: #2d2d2d;
    border-radius: 0.5rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    padding: 0rem;
    margin: 1rem 0;
    width: 30%;
    flex-shrink: 0; /* Prevent form from shrinking */
}

.search-form--compact {
    padding: 0;
    margin: 0;
    transform: scale(0.75);
    transform-origin: right center;
    width: auto; /* Allow form to size based on content */
    max-width: 400px; /* Maximum width */
    min-width: 200px; /* Minimum width */
}

.search-bar-container {
    flex: 1;
    position: relative;
    min-width: 0; /* Allow container to shrink */
}

.search-input {
    width: 100%;
    background: transparent;
    color: #ffffff;
    border: none;
    outline: none;
    font-size: 1rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
}


/* Keep rest of your existing styles (spinner, error message, etc.) */ 
  .search-input::placeholder {
    color: #9ca3af;
  }
  
  .search-form:focus-within {
    outline: none;
    box-shadow: 0 0 0 2px rgb(174, 129, 223); /* Blue glow on focus */
  }
  
  .search-input:disabled {
    background-color: #4b5563;
    cursor: not-allowed;
    color: #9ca3af;
  }
  
  .search-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(174, 129, 223);
    color: white;
    border: none;
    padding: 0.5rem;
    border-radius: 0.375rem;
    cursor: pointer;
    transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
  }
  
  .search-button:hover:not(:disabled) {
    background-color: rgb(113, 58, 172);
    transform: scale(1.05);
  }
  
  .search-button:disabled {
    background-color: #4b5563;
    cursor: not-allowed;
  }
  
  .search-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .loading-container {
    display: flex;
    align-items: center;
  }
  
  .loading-icon {
    width: 1rem;
    height: 1rem;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .spinner {
  width: 1rem;
  height: 1rem;
  border: 2px solid transparent;
  border-top: 2px solid white; /* Visible section */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.spinner {
    width: 1rem;
    height: 1rem;
    border: 2px solid transparent;
    border-top: 2px solid white; /* Visible section */
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .error-message {
    color: #dc2626;
    font-size: 0.875rem;
    margin-top: 0.5rem;
    text-align: center;
  }

  .demo-results {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  @media (max-width: 1024px) {
    .demo-results {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .demo-results {
      grid-template-columns: 1fr;
    }
  }
  
  .search-input[data-term-index="0"] { color: #FF6B6B; }
  .search-input[data-term-index="1"] { color: #4ECDC4; }
  .search-input[data-term-index="2"] { color: #FFD93D; }
  .search-input[data-term-index="3"] { color: #95E1D3; }
  .search-input[data-term-index="4"] { color: #FF8B94; }
  
  /* Typing animation cursor */
  .search-input.typing::after {
    content: '|';
    animation: blink 1s step-end infinite;
  }
  
  @keyframes blink {
    from, to { opacity: 1; }
    50% { opacity: 0; }
  }
