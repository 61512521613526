.auth-container {
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    background-color: rgb(24, 24, 27);
    color: rgb(243, 244, 246);
}

.auth-box-wrapper {
    position: relative;
    width: 100%;
    max-width: 900px;
}

.back-button {
    position: absolute;
    top: 2rem;
    left: .25rem;
    background: none;
    border: none;
    color: rgb(243, 244, 246);
    font-size: 1.1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    transition: all 0.2s ease;
    border-radius: 4px;
    z-index: 10;
}

.back-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
    transform: translateX(-3px);
}

.auth-box {
    background: rgb(39, 39, 42);
    border-radius: 12px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.25);
    width: 100%;
    max-width: 900px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    overflow: hidden;
}

/* The rest of your CSS remains unchanged */
.auth-left {
    flex: 1;
    background: linear-gradient(#6c0cb0, #8973e0, #5f42d4);
    padding: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    overflow: hidden;
}



.brand-logo {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    color: white;
}

.welcome-text {
    margin-bottom: 1.5rem;
}

.welcome-text h1 {
    font-size: 2.2rem;
    font-weight: 700;
    color: white;
    margin-bottom: 1rem;
}

.welcome-text p {
    font-size: 1.1rem;
    color: rgba(255, 255, 255, 0.8);
    line-height: 1.6;
}

.features-list {
    margin-top: 2rem;
}

.features-list ul {
    list-style: none;
    padding: 0;
}

.features-list li {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    color: rgba(255, 255, 255, 0.9);
}

.features-list li:before {
    content: "✓";
    margin-right: 10px;
    color: white;
    font-weight: bold;
}

.auth-right {
    flex: 1;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.auth-header {
    text-align: left;
    margin-bottom: 2rem;
}

.auth-header h2 {
    color: rgb(243, 244, 246);
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
    padding: 0;
}

.auth-header p {
    color: rgb(156, 163, 175);
    font-size: 1rem;
}

.auth-form {
    display: flex;
    flex-direction: column;
    padding: 0;
}

.form-group {
    width: 100%;
    position: relative;
    margin-bottom: 0;
}

.form-group input {
    width: 100%;
    padding: 0.9rem 1rem;
    background-color: rgb(24, 24, 27);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    font-size: 1rem;
    color: rgb(243, 244, 246);
    transition: all 0.3s ease;
    margin: 0;
}

.form-group input:focus {
    outline: none;
    border-color: rgb(59, 130, 246);
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.3);
}

.form-group input::placeholder {
    color: rgb(156, 163, 175);
}

.auth-submit {
    width: 100%;
    background-color: rgb(174, 129, 223);
    color: white;
    padding: 0.9rem;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 1rem 0rem;
}

.auth-submit:hover {
    background-color: rgb(113, 58, 172);
    transform: translateY(-1px);
}

.auth-submit:active {
    transform: translateY(1px);
}

.auth-submit:disabled {
    background-color: rgb(75, 85, 99);
    cursor: not-allowed;
    transform: none;
}

.auth-footer {
    text-align: center;
    margin-top: 2rem;
    color: rgb(156, 163, 175);
}

.toggle-mode {
    background: none;
    border: none;
    color: rgb(174, 129, 223);
    font-weight: 500;
    cursor: pointer;
    padding: 0 0.5rem;
    font-size: 1rem;
    transition: color 0.2s ease;
}

.toggle-mode:hover {
    background-color: transparent;
    color: rgb(113, 58, 172);
    text-decoration: underline;
}

.error-message {
    color: rgb(239, 68, 68);
    margin: 12px 0;
    text-align: center;
    padding: 8px;
    background-color: rgba(239, 68, 68, 0.1);
    border-radius: 6px;
    font-size: 0.9rem;
}

.social-auth {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    position: relative;
}

.social-auth::before {
    content: "or";
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgb(39, 39, 42);
    padding: 0 12px;
    color: rgb(156, 163, 175);
    font-size: 0.9rem;
}

.google-auth-button {
    width: 100%;
    padding: 0.9rem;
    margin: 0;
    margin-top: .9rem;
    background-color: rgb(24, 24, 27);
    color: rgb(243, 244, 246);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    transition: all 0.3s ease;
    font-weight: 500;
}

.google-auth-button:hover {
    background-color: rgba(255, 255, 255, 0.05);
}

.google-auth-button:active {
    transform: translateY(1px);
}

.google-icon {
    width: 18px;
    height: 18px;
}

.mt-4 {
    margin-top: 1.5rem;
}

/* Plan selection styling */
.plan-selection {
    display: grid;
    gap: 1rem;
    margin: 1.5rem 0;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .auth-box {
        flex-direction: column;
        max-width: 420px;
    }
    
    .auth-left {
        padding: 2rem;
        min-height: 200px;
    }
    
    .auth-right {
        padding: 2rem;
    }
    
    .welcome-text h1 {
        font-size: 1.8rem;
    }
    
    .features-list {
        display: none;
    }
    
    .back-button {
        top: -2.5rem;
    }
}

@media (max-width: 480px) {
    .auth-container {
        padding: 1rem;
    }
    
    .auth-box {
        border-radius: 8px;
    }
    
    .auth-left, .auth-right {
        padding: 1.5rem;
    }
    
    .back-button {
        top: -2rem;
        font-size: 1rem;
    }
}