.video-card {
  background-color: rgb(54, 54, 58);
  border-radius: 0.5rem;
  overflow: hidden;
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: grid;
  grid-template-columns: 360px 1fr; /* Desktop: Thumbnail left, content right */
  gap: 16px;
}

.video-card:hover {
  background-color: rgb(39, 39, 42);
}

.video-card__thumbnail-container {
  position: relative;
}

.video-card__thumbnail {
  width: 100%;
  object-fit: cover;
}

.video-card__duration {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  margin-right: 1%;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 0.125rem 0.375rem;
  font-size: 0.75rem;
  border-radius: 0.25rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.video-card__duration-icon {
  display: inline;
}

.video-card__content {
  padding: 0.75rem;
}

.video-card__title {
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.25rem;
  color: rgb(243, 244, 246);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.video-card__channel {
  font-size: 0.875rem;
  color: rgb(156, 163, 175);
  font-weight: 400;
  margin-bottom: 0.25rem;
  padding: 0.25rem;
}

.video-card__stats {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 0.75rem;
  color: rgb(156, 163, 175);
}

.video-card__stat {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin-bottom: .5rem;
}

.video-card__icon {
  display: inline;
  opacity: 0.75;
}

/* Responsive layout for smaller screens */
/* Updated responsive layout for phones */

.video-card {
  grid-template-columns: 1fr; /* Stack layout */
  gap: 8px;
}

.video-card__thumbnail-container {
  width: 100%;
  aspect-ratio: 16 / 9;
}

.video-card__content {
  padding: .25rem;
}