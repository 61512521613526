.about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin: auto;
    color: white;
    text-align: center;
}

.about-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 900px;
    margin-bottom: 40px;
}

.about-section.reverse {
    flex-direction: row-reverse;
}

.about-image {
    width: 35%;
    border-radius: 10px;
}

.about-text {
    width: 50%;
    padding: 20px;
}

.about-header {
    font-size: 24px;
    margin-bottom: 10px;
}

.about-description {
    font-size: 16px;
    line-height: 1.5;
}
